import _ from 'lodash';
import sortable from 'sortablejs';
import vkbeautify from 'vkbeautify';

//DO THIS LINE FIRST BECAUSE OF NODE VERSION!!!!
//export NODE_OPTIONS=--openssl-legacy-provider

// import lang from 'suneditor/src/lang';
// import {en, ro, ko} from 'suneditor/src/lang';
// import plugins from 'suneditor/src/plugins';
// import suneditor from 'suneditor';
import tail from 'tail.datetime';
// import ClipboardJS from 'clipboard';

let sprintf = require( 'sprintf-js' ).sprintf, vsprintf = require( 'sprintf-js' ).vsprintf;

let apiURLS = {
	// 'ajaxGetCompany' 			: SITE_FULL + 'ajax/getcompanyrvx/',
	'ajaxGetCompany' 			: SITE_FULL + 'ajax/getcompany/',
	'clientWorkstations' 		: SITE_FULL + 'api/client/%d/workstations/',
	'clientsSearch' 			: SITE_FULL + 'api/clients/search/',
	'departmentAddUsers' 		: SITE_FULL + 'api/department-add-users/',
	'departmentRemoveAllUsers' 	: SITE_FULL + 'api/department-remove-all-users/',
	'fileUpload' 				: SITE_FULL + 'api/file/upload/',
	'fileChunks' 				: SITE_FULL + 'api/file/chunks/',
	'clientsImport' 			: SITE_FULL + 'api/clients/import/',
	'importReports' 			: SITE_FULL + 'api/import/reports/',
	'setTaskStatus' 			: SITE_FULL + 'api/set-task-status/',
	'setTaskUser' 				: SITE_FULL + 'api/set-task-user/',
	'sortStatuses' 				: SITE_FULL + 'api/sort-statuses/',	
	'sortDepartments' 			: SITE_FULL + 'api/sort-departments/',
};
let tinyMCEOptions = {
	selector: 'textarea.editorRich',
	height: 500,
	menubar: false,
	plugins: [
		'advlist autolink lists link image charmap print preview anchor',
		'searchreplace visualblocks code fullscreen',
		'insertdatetime media table paste code help wordcount'
	],
	toolbar: 'undo redo | formatselect | ' +
		'bold italic backcolor | alignleft aligncenter ' +
		'alignright alignjustify | bullist numlist outdent indent | ' +
		'removeformat | table | help code',
	content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
};
let listDynamicAddedElements = [];


(function(funcName, baseObj) {
	"use strict";
	// The public function name defaults to window.docReady
	// but you can modify the last line of this function to pass in a different object or method name
	// if you want to put them in a different namespace and those will be used instead of 
	// window.docReady(...)
	funcName = funcName || "docReady";
	baseObj = baseObj || window;
	var readyList = [];
	var readyFired = false;
	var readyEventHandlersInstalled = false;

	// call this when the document is ready
	// this function protects itself against being called more than once
	function ready() {
		if (!readyFired) {
			// this must be set to true before we start calling callbacks
			readyFired = true;
			for (var i = 0; i < readyList.length; i++) {
				// if a callback here happens to add new ready handlers,
				// the docReady() function will see that it already fired
				// and will schedule the callback to run right after
				// this event loop finishes so all handlers will still execute
				// in order and no new ones will be added to the readyList
				// while we are processing the list
				readyList[i].fn.call(window, readyList[i].ctx);
			}
			// allow any closures held by these functions to free
			readyList = [];
		}
	}
	
	function readyStateChange() {
		if ( document.readyState === "complete" ) {
			ready();
		}
	}
	
	// This is the one public interface
	// docReady(fn, context);
	// the context argument is optional - if present, it will be passed
	// as an argument to the callback
	baseObj[funcName] = function(callback, context) {
		if (typeof callback !== "function") {
			throw new TypeError("callback for docReady(fn) must be a function");
		}
		// if ready has already fired, then just schedule the callback
		// to fire asynchronously, but right away
		if (readyFired) {
			setTimeout(function() {callback(context);}, 1);
			return;
		} else {
			// add the function and context to the list
			readyList.push({fn: callback, ctx: context});
		}
		// if document already ready to go, schedule the ready function to run
		// IE only safe when readyState is "complete", others safe when readyState is "interactive"
		if (document.readyState === "complete" || (!document.attachEvent && document.readyState === "interactive")) {
			setTimeout(ready, 1);
		} else if (!readyEventHandlersInstalled) {
			// otherwise if we don't have event handlers installed, install them
			if (document.addEventListener) {
				// first choice is DOMContentLoaded event
				document.addEventListener("DOMContentLoaded", ready, false);
				// backup is window load event
				window.addEventListener("load", ready, false);
			} else {
				// must be IE
				document.attachEvent("onreadystatechange", readyStateChange);
				window.attachEvent("onload", ready);
			}
			readyEventHandlersInstalled = true;
		}
	}
})("docReady", window);

let listModelSeries = {
	//DATECS
	DB4080 : {
		model : 'BC50',
		aviz : 'REPLACE_AMEF_BC50',
		dataName: 'Datecs',
		dataModel: 'BC 50',
		dataAviz: '137/26.08.2021',
	},
	DB4090 : {
		model : 'DP25 MX',
		aviz : 'REPLACE_AMEF_DP25MX',
		dataName: 'Datecs',
		dataModel: 'DP-25MX',
		dataAviz: '142/10.11.2021',
	},
	DB4010 : {
		model : 'FMP350',
		aviz : 'REPLACE_AMEF_FMP350',
		dataName: 'Datecs',
		dataModel: 'FMP350',
		dataAviz: '129/16.01.2020',
	},
	DB4110 : {
		model : 'WP50 MX',
		aviz : 'REPLACE_AMEF_WP50MX',
		dataName: 'Datecs',
		dataModel: 'WP-50 MX',
		dataAviz: '141/10.11.2021',
	},
	DB4111 : {
		model : 'DP150 MX',
		aviz : 'REPLACE_AMEF_DP150MX',
		dataName: 'Datecs',
		dataModel: 'DP-150 MX',
		dataAviz: '140/10.11.2021',
	},
	DB4120 : {
		model : 'DP05 MX',
		aviz : 'REPLACE_AMEF_DP150MX',
		dataName: 'Datecs',
		dataModel: 'DP-05 MX',
		dataAviz: '164/20.07.2023',
	},
	DB402 : {
		model : 'SK1-21',
		aviz : 'REPLACE_AMEF_SK121',
		dataName: 'Datecs',
		dataModel: 'SK1-21',
		dataAviz: '153/30.03.2023',
	},

	DB41 : {
		model : 'DP150',
		aviz : 'REPLACE_AMEF_DP150',
		dataName: 'Datecs',
		dataModel: 'DP150',
		dataAviz: '1/24.05.2018',
	},
	DB42 : {
		model : 'DP25',
		aviz : 'REPLACE_AMEF_DP25',
		dataName: 'Datecs',
		dataModel: 'DP25',
		dataAviz: '2/24.05.2018',
	},
	DB43 : {
		model : 'WP500',
		aviz : 'REPLACE_AMEF_WP500',
		dataName: 'Datecs',
		dataModel: 'WP500',
		dataAviz: '6/24.05.2018',
	},
	DB44 : {
		model : 'WP50',
		aviz : 'REPLACE_AMEF_WP50',
		dataName: 'Datecs',
		dataModel: 'WP50',
		dataAviz: '3/24.05.2018',
	},
	DB45 : {
		model : 'DP05',
		aviz : 'REPLACE_AMEF_DP05',
		dataName: 'Datecs',
		dataModel: 'DP05',
		dataAviz: '112/13.06.2019',
	},
	DB46 : {
		model : 'DP25 X',
		aviz : 'REPLACE_AMEF_DP25X',
		dataName: 'Datecs',
		dataModel: 'DP25X',
		dataAviz: '111/13.06.2019',
	},
	DB47 : {
		model : 'FP700',
		aviz : 'REPLACE_AMEF_FP700',
		dataName: 'Datecs',
		dataModel: 'FP700',
		dataAviz: '4/24.05.2018',
	},
	DB48 : {
		model : 'FP800',
		aviz : 'REPLACE_AMEF_FP800',
		dataName: 'Datecs',
		dataModel: 'FP800',
		dataAviz: '5/24.05.2018',
	},
	DB49 : {
		model : 'FP650',
		aviz : 'REPLACE_AMEF_FP650',
		dataName: 'Datecs',
		dataModel: 'FP650',
		dataAviz: '7/24.05.2018',
	},
	//PARTNER
	PI : {
		model : '200',
		aviz : 'REPLACE_AMEF_P200',
		dataName: 'Partner',
		dataModel: '200',
		dataAviz: '16/28.06.2018',
	},
	PN : {
		model : '600',
		aviz : 'REPLACE_AMEF_P600',
		dataName: 'Partner',
		dataModel: '600',
		dataAviz: '25/13.08.2018',
	},
	//TREMOL
	DP : {
		model : 'M20',
		aviz : 'REPLACE_AMEF_TM20',
		dataName: 'Tremol',
		dataModel: 'M20',
		dataAviz: '17/09.07.2018',
	},
	DP300 : {
		model : 'S25',
		aviz : 'REPLACE_AMEF_S25',
		dataName: 'Tremol',
		dataModel: 'S25',
		dataAviz: '132/26.02.2020',
	},
};

docReady(function()
{
	//document
	addEventToElement( document.querySelector( '#header .menu-icon' ), 'click',
		function(){ var menuList = document.querySelector( '.section-menu' ); if( hasClass( menuList, 'disp-none' ) ) removeClass( menuList, 'disp-none' ); else addClass( menuList, 'disp-none' ); });
	addEventToElement( document.querySelector( '.action-selection-contract' ), 'change', actionSelectionContract );
	addEventToElement( document.querySelector( '.action-get-company-data' ), 'click', actionGetCompanyData );
	addEventToElement( document.querySelector( '#overlay .overlay-buttons .btn-close' ), 'click', closeOverlay );
	addEventToElement( document.querySelector( '#overlay .overlay-content' ), 'click', function( event ){ event.stopPropagation(); document.querySelector( '#overlay' ).style.display = 'none'; })
	addEventToElement( document.querySelector( '#overlay .overlay-content-container' ), 'click', function( event ){ event.stopPropagation(); })
	addEventToElement( document.querySelector( '.action-selection-department' ), 'change', actionSelectionTaskUsersByDepartment );
	addEventToElement( document.querySelector( '.action-selection-client-name' ), 'keyup', actionSelectionTaskClientName );
	addEventToElement( document.querySelector( '.action-selection-client-name' ), 'change', actionSelectionTaskClientNameChange );
	addEventToElement( document.querySelector( '.action-change-reassign-from' ), 'change', actionSelectionReassignFromTo );
	addEventToElement( document.querySelector( '.action-change-reassign-to' ), 'change', actionSelectionReassignFromTo );
	addEventToElement( document.querySelector( '.action-load-more' ), 'click', actionLoadMore );
	addEventToElement( document.querySelector( '.action-send-amef' ), 'click', actionSendAmef );
	addEventToElement( document.querySelector( '#action-is-comert' ), 'click', actionIsComert );
	addEventToElement( document.querySelector( '#contract-template-kind' ), 'change', actionChangeContratTemplateKind );
	addEventToElement( document.querySelector( '#contract-model-template-kind' ), 'change', actionChangeContractModelTemplateKind );
	addEventToElement( document.querySelector( '#form-item form' ), 'submit', actionSubmitForm );

	actionAutoremoveAlerts();

	if( document.querySelector( 'form select[name="REPLACE_DI_TIP"]' ) ) {
		actionChangeContractDecInstTip( document.querySelector( 'select[name="REPLACE_DI_TIP"]' ) );
	}
	document.addEventListener( 'change', function(e) {
		if( e.target === document.querySelector( 'form select[name="REPLACE_DI_TIP"]' ) ) {
			actionChangeContractDecInstTip( e.target );
		}
	});

	addEventOutsideElem( 'click', '.action-options', closeActionOptions );

	addEventToNodeList( document.querySelectorAll( '.action-form-reset' ), 'click', actionFormReset );
	addEventToNodeList( document.querySelectorAll( '.action-assign-task' ), 'click', actionAssignTask );
	addEventToNodeList( document.querySelectorAll( '.action-assign-task-name' ), 'click', actionAssignTaskName );
	addEventToNodeList( document.querySelectorAll( '.action-options' ), 'click', actionOptions );
	addEventToNodeList( document.querySelectorAll( '.action-reverse' ), 'click', function(){
		var elemI = this.find( '> i' )[0];
		var elemSpan = this.find( '> span' )[0];

		var elemIClassNew = elemI.getAttribute( 'btn-reverse' );
		var elemIClassOld = elemI.getAttribute( 'class' );

		var elemSpanTextNew = elemSpan.getAttribute( 'btn-reverse' );
		var elemSpanTextOld = elemSpan.innerHTML;

		forEach( elemIClassOld.split( ' ' ), function ( index, elem ) { elemI.classList.remove( elem ); });
		forEach( elemIClassNew.split( ' ' ), function ( index, elem ) { elemI.classList.add( elem ); });
		elemI.setAttribute( 'btn-reverse', elemIClassOld );

		elemSpan.innerHTML = elemSpanTextNew;
		elemSpan.setAttribute( 'btn-reverse', elemSpanTextOld );

		var elemDetail = this.closest( '.listing-row' ).nextElementSibling;
		if( hasClass( elemDetail, 'listing-visible' ) ) removeClass( elemDetail, 'listing-visible' ); else addClass( elemDetail, 'listing-visible' );
	});

	addEventToNodeList( document.querySelectorAll( '.action-change-status' ), 'click', actionChangeStatus );
	addEventToNodeList( document.querySelectorAll( '.action-change-status-name' ), 'click', actionChangeStatusName );
	addEventToNodeList( document.querySelectorAll( '.action-alert-before' ), 'click', actionAlertBefore );
	addEventToNodeList( document.querySelectorAll( '.action-show-note-form' ), 'click', actionShowNoteForm );
	addEventToNodeList( document.querySelectorAll( '.action-download-report' ), 'click', actionDownloadReport );
	addEventToNodeList( document.querySelectorAll( '.action-upload-file' ), 'click', actionUploadFile );
	addEventToNodeList( document.querySelectorAll( '.action-show-last-note' ), 'click', actionShowLastNote );
	addEventToNodeList( document.querySelectorAll( '.action-show-last-note-inside' ), 'click', actionShowLastNoteInside );
	addEventToNodeList( document.querySelectorAll( '.action-report-contract-model' ), 'click', actionReportContractModel );

	addEventEscDetected();

	addSortable( '.listing-sortable-statuses', sortStatuses );
	addSortable( '.listing-sortable-departments', sortDepartments );
	addSortableGroups( '.section-sortable-left', sortDepartmentUsers );
	addSortableGroups( '.section-sortable-right', sortDepartmentUsers );

	populateContract();
	populateTaskUsersByDepartment();

	setTailDateSelectorsCommon();
	if( checkImport )
		checkImportProgress();

	var tinyMCEOptionsOther = _.cloneDeep( tinyMCEOptions );
	tinyMCEOptionsOther.selector = 'textarea.wysiwyg';

	tinymce.init( tinyMCEOptionsOther );

	if( typeof xmlString !== 'undefined' && xmlString != null ) {
		document.querySelector('.preview-xml').innerHTML = vkbeautify.xml( xmlString );
	}
});

//ACTIONS
function actionSubmitForm( e ) {
	e.preventDefault();
	var ok = true;
	forEach( e.target.querySelectorAll( '#add-specific-fields input[required], #add-specific-fields select[required]' ), function ( index, elem ) {
		removeClass( elem, 'error' );
		if( !elem.value ) { ok = false; addClass( elem, 'error' ); }
	});

	if( ok )
		e.currentTarget.submit();
}
function actionChangeContractDecInstTip( e ) {
	forEach( document.querySelectorAll( 'form select[name="REPLACE_DI_MODEL"] option' ), function ( index, elem ) {
		elem.setAttribute( 'disabled', true );
		if( elem.getAttribute( 'data-tip' ).toString() === e.value.toString() )
			elem.removeAttribute( 'disabled' );
	});
	document.querySelector( 'form select[name="REPLACE_DI_MODEL"]' ).value = document.querySelector( 'form select[name="REPLACE_DI_MODEL"] option:not(:disabled)' ).value;

	forEach( document.querySelectorAll( 'form select[name="REPLACE_DI_AVIZ"] option' ), function ( index, elem ) {
		elem.setAttribute( 'disabled', true );
		if( elem.getAttribute( 'data-tip' ).toString() === e.value.toString() )
			elem.removeAttribute( 'disabled' );
	});
	document.querySelector( 'form select[name="REPLACE_DI_AVIZ"]' ).value = document.querySelector( 'form select[name="REPLACE_DI_AVIZ"] option:not(:disabled)' ).value;
}
function actionChangeContractModelTemplateKind( e ) {
	forEach( document.querySelectorAll( 'div[data-row^="T-"]' ), function ( index, elem ) {
		removeClass( elem, 'listing-hidden' );
		addClass( elem, 'listing-hidden' );
	});
	removeClass( document.querySelector( 'div[data-row="T-'+ this.value +'"]' ), 'listing-hidden' );
}
function actionChangeContratTemplateKind( e ){
	forEach( document.querySelectorAll( 'div[data-row^="T-"]' ), function ( index, elem ) {
		removeClass( elem, 'listing-hidden' );
		addClass( elem, 'listing-hidden' );
	});
	removeClass( document.querySelector( 'div[data-row="T-'+ this.value +'"]' ), 'listing-hidden' );
}
function actionShowLastNoteInside() {
	forEach( document.querySelectorAll( '.listing-hidden.show' ), function ( index, elem ) {
		removeClass( elem, 'show' );
	});

	var theElem = this.parentNode.parentNode.parentNode.nextSibling;
	while( theElem && theElem.nodeType !== 1 )
		theElem = theElem.nextSibling;

	addClass( theElem, 'show' );
}

function actionShowLastNote() {
	forEach( document.querySelectorAll( '.listing-hidden.show' ), function ( index, elem ) {
		removeClass( elem, 'show' );
	});

	var theElem = this.parentNode.parentNode.parentNode.parentNode.parentNode.nextSibling;
	while( theElem && theElem.nodeType !== 1 )
		theElem = theElem.nextSibling;

	addClass( theElem, 'show' );
}

function actionUploadFile( event )
{
	event.preventDefault();

	var thisElem = this;
	var elemForm = this.closest( 'form' );
	var elemFile = elemForm.find( 'input[type="file"]' );

	if( elemFile.length <= 0 || elemFile[0].files.length <= 0 ) return;

	var theFile = elemFile[0].files[0];
	var formData = new FormData();
	formData.append( 'file', theFile );

	var eventListeners = [
		{
			"key": "progress",
			"value": 
				function( event ){
					if( event.lengthComputable )
						actionSetProgressBarPercent( document.querySelector( '.progress-bar-client' ), Math.round( event.loaded / event.total * 100 ) );
				}
		}
	];

	removeClass( document.querySelector( '.progress-bar-client' ), 'nothing' );

	var messageList = { "success": [], "error": [], "totalTo": 0, "totalPr": 0 };

	let filtersTot = {
		"filterName": "is_seen",
		"filterItems": [
			{
				"filterSubName": "is_seen",
				"filterOperator": "=",
				"filterOperatorBy": "AND",
				"filterType": "text",
				"filterKey": [
					"is_seen"
				],
				"filterValue": [
					"no"
				]
			}
		]
	};
	let filtersProcesed = {
		"filterName": "processed",
		"filterItems": [
			{
				"filterSubName": "is_seen",
				"filterOperator": "=",
				"filterOperatorBy": "AND",
				"filterType": "text",
				"filterKey": [
					"is_seen"
				],
				"filterValue": [
					"no"
				]
			},
			{
				"filterSubName": "status",
				"filterOperator": "=",
				"filterOperatorBy": "AND",
				"filterType": "text",
				"filterKey": [
					"status"
				],
				"filterValue": [
					"finished"
				]
			}
		]
	};

	var fileOriginal = '';
	requestHTTP( 'POST', apiURLS.fileUpload, formData, [], null, eventListeners, true )
		.then(
			function( resolve ) {
				messageList[resolve.type].push( resolve.message );
				fileOriginal = resolve.original;
				return requestHTTP( 'POST', apiURLS.fileChunks, JSON.stringify({ file: resolve.results }) );
			}
		)
		.then(
			function( resolve ) {
				messageList[resolve.type].push( resolve.message );
				return requestHTTP( 'POST', apiURLS.clientsImport, JSON.stringify({ files: resolve.results, fileOriginal: fileOriginal, how: document.querySelector( 'select[name="how"]' ).value }) );
			}
		)
		.then(
			function( resolve ) {
				messageList[resolve.type].push( resolve.message );
				return requestHTTP( 'POST', apiURLS.importReports, JSON.stringify({ getCount: true, filters: filtersTot }) );
			}
		)
		.then(
			function( resolve ) {
				if( parseInt( resolve.results ) > 0 )
					messageList["totalTo"] = resolve.results;
				return requestHTTP( 'POST', apiURLS.importReports, JSON.stringify({ getCount: true, filters: filtersProcesed  }) );
			}
		)
		.then(
			function( resolve ) {
				if( parseInt( resolve.results ) > 0 )
					messageList["totalPr"] = resolve.results;
				addClass( document.querySelector( '.progress-bar-client' ), 'nothing' );
				document.querySelector( '.section-form form' ).reset();

				var htmlAlerts = messageList.success.length ? templateDefault( messageList.success.join( '<br>' ), 'success', false, true ) : '';
				htmlAlerts += messageList.error.length ? templateDefault( messageList.error.join( '<br>' ), 'error', false, true ) : '';
				document.querySelector( '#alerts' ).innerHTML = htmlAlerts;
				actionAutoremoveAlerts( 5 );

				removeClass( document.querySelector( '#progress-bar' ).find( '.progress-bar-main' )[0], 'progress-bar-reverse' );
				if( messageList["totalPr"] == messageList["totalTo"] )
					addClass( document.querySelector( '#progress-bar' ).find( '.progress-bar-main' )[0], 'progress-bar-reverse' );

				if( parseInt( messageList["totalPr"] ) >= 0 && parseInt( messageList["totalTo"] ) > 0 )
				{
					removeClass( document.querySelector( '#progress-bar' ), 'nothing' );
					let extra = ( messageList["totalPr"] == messageList["totalTo"] ) ? '100%' : ( messageList["totalPr"] + " / " + messageList["totalTo"] + " " + ( messageList["totalTo"] == 1 ? GLOBALS['javascript']['file-process'] : GLOBALS['javascript']['files-process'] ) );
					let percent = messageList["totalPr"] == 0 ? ( 0.1 / messageList["totalTo"] ) * 100 : ( messageList["totalPr"] / messageList["totalTo"] ) * 100;
					actionSetProgressBarPercent( document.querySelector( '#progress-bar' ).find( '.progress-bar-main' )[0], percent, extra )
					setTimeout( checkImportProgress, 10000 );
				}
			},
			function( reject ) {
				addClass( document.querySelector( '.progress-bar-client' ), 'nothing' );
				document.querySelector( '#alerts' ).innerHTML = templateDefault( reject.message, reject.type, false, true );
				actionAutoremoveAlerts();
			}
		)
		.catch(
			function( error ) {
				addClass( document.querySelector( '.progress-bar-client' ), 'nothing' );
			}
		);
}

window.checkImportProgress = () => {
   var dataInfo = { "totalTo": 0, "totalPr": 0 };

	let filtersTot = {
		"filterName": "is_seen",
		"filterItems": [
			{
				"filterSubName": "is_seen",
				"filterOperator": "=",
				"filterOperatorBy": "AND",
				"filterType": "text",
				"filterKey": [
					"is_seen"
				],
				"filterValue": [
					"no"
				]
			}
		]
	};
	let filtersProcesed = {
		"filterName": "processed",
		"filterItems": [
			{
				"filterSubName": "is_seen",
				"filterOperator": "=",
				"filterOperatorBy": "AND",
				"filterType": "text",
				"filterKey": [
					"is_seen"
				],
				"filterValue": [
					"no"
				]
			},
			{
				"filterSubName": "status",
				"filterOperator": "=",
				"filterOperatorBy": "AND",
				"filterType": "text",
				"filterKey": [
					"status"
				],
				"filterValue": [
					"finished"
				]
			}
		]
	};

	requestHTTP( 'POST', apiURLS.importReports, JSON.stringify({ getCount: true, filters: filtersTot  }) )
	.then(
		function( resolve ) {
			if( parseInt( resolve.results ) > 0 )
				dataInfo["totalTo"] = resolve.results;
			return requestHTTP( 'POST', apiURLS.importReports, JSON.stringify({ getCount: true, filters: filtersProcesed }) );
		}
	)
	.then(
		function( resolve ) {
			if( parseInt( resolve.results ) > 0 )
				dataInfo["totalPr"] = resolve.results;

			removeClass( document.querySelector( '#progress-bar' ).find( '.progress-bar-main' )[0], 'progress-bar-reverse' );
			if( dataInfo["totalPr"] == dataInfo["totalTo"] )
				addClass( document.querySelector( '#progress-bar' ).find( '.progress-bar-main' )[0], 'progress-bar-reverse' );

			if( parseInt( dataInfo["totalPr"] ) >= 0 && parseInt( dataInfo["totalTo"] ) > 0 )
			{
				removeClass( document.querySelector( '#progress-bar' ), 'nothing' );
				let extra = ( dataInfo["totalPr"] == dataInfo["totalTo"] ) ? '<a class="btn btn-view" href="' + SITE_FULL + 'reports/' + '"><i class="far fa-eye"></i> ' + ( dataInfo["totalTo"] == 1 ? GLOBALS['buttons']['view-report'] : GLOBALS['buttons']['view-reports'] ) + '</a>' : ( dataInfo["totalPr"] + " / " + dataInfo["totalTo"] + " " + ( dataInfo["totalTo"] == 1 ? GLOBALS['javascript']['file-process'] : GLOBALS['javascript']['files-process'] ) );
				let percent = dataInfo["totalPr"] == 0 ? ( 0.1 / dataInfo["totalTo"] ) * 100 : ( dataInfo["totalPr"] / dataInfo["totalTo"] ) * 100;
				actionSetProgressBarPercent( document.querySelector( '#progress-bar' ).find( '.progress-bar-main' )[0], percent, extra )
				if( parseInt( dataInfo["totalPr"] ) < parseInt( dataInfo["totalTo"] ) )
					setTimeout( checkImportProgress, 10000 );
			}
		}
	)
	.catch(
		function( error ) {}
	);
};

function actionSetProgressBarPercent( element, percent = 0, extra = '' )
{
	if( typeof element === 'undefined' || percent < 0 || percent > 100 ) return;

	if( extra != '' )
		element.find( '.info p' )[0].innerHTML = extra;
	else
		element.find( '.info p' )[0].innerHTML = percent+'%';
	element.find( '.paper-progress-bar' )[0].style.right = ( percent == 100 ? 15 + "px" : ( 100 - percent ) + "%" );
}
function actionDownloadReport( event )
{
	event.preventDefault();
	listingToCSV( document.querySelector( this.getAttribute( 'data-target' ) ) );
}
function actionLoadMore()
{
	if( !this.getAttribute( 'data-elem' ) || this.getAttribute( 'data-elem' ) == '' || !this.getAttribute( 'data-remove' ) || this.getAttribute( 'data-remove' ) == '' )
		return;

	let limit = 10;
	if( this.getAttribute( 'data-limit' ) && parseInt( this.getAttribute( 'data-limit' ) ) > 0 )
		limit = parseInt( this.getAttribute( 'data-limit' ) );

	let listElem = document.querySelectorAll( this.getAttribute( 'data-elem' ) );
	let elemRemove = this.getAttribute( 'data-remove' );

	for ( var i = 0; i < limit; i++ )
		if( typeof listElem[i] !== 'undefined' )
			removeClass( listElem[i], elemRemove );

	if( document.querySelectorAll( this.getAttribute( 'data-elem' ) ).length <= 0 )
		this.style.display = 'none';
}
function actionSelectionReassignFromTo()
{
	var other = document.querySelector( '.action-change-reassign-to' );
	if( hasClass( this, 'action-change-reassign-to' ) )
		other = document.querySelector( '.action-change-reassign-from' );

	if( other.value == this.value )
		other.value = '';

	forEach( other.find( 'option' ), function ( index, elem ) {
		elem.removeAttribute( 'disabled' );
	});
	forEach( this.find( 'option' ), function ( index, elem ) {
		elem.removeAttribute( 'disabled' );
	});
}
function populateTaskClientWorkstation( client_id )
{
	if( parseInt( client_id ) <= 0 ) return;

	requestHTTP( 'GET', sprintf( apiURLS.clientWorkstations, client_id ) + '?getAll=true' )
		.then(
			function( resolve ) {
				if( resolve.type == 'success' && resolve.results.length > 0 )
				{
					var selectedOption = document.querySelector( 'select[name="workstation_id"]' ).value;
					var options = '';
					forEach( resolve.results, function ( index, elem ) {
						options += '<option value="' + elem['id'] + '"' + ( selectedOption == elem['id'] ? ' selected="selected"':'' ) + '>' + elem['name'] + '</option>';
					});

					document.querySelector( 'select[name="workstation_id"]' ).innerHTML = options;
				}
			},
			function( reject ) {
				if ( reject.statusCode == 404 ) {
					document.querySelector( '#alerts' ).innerHTML = templateDefault( GLOBALS['errors']['generic']['workstations_notfound'], reject.type, false, true );
					actionAutoremoveAlerts();

					document.querySelector( 'select[name="workstation_id"]' ).style.display = 'none';
					document.querySelector( '.action-no-workstation' ).style.display = 'block';
					document.querySelector( '.action-no-workstation' ).find( 'a' )[0].setAttribute( 'href', SITE_FULL + 'clients/' + document.querySelector( 'input[name="client_id"]' ).value + '/workstations/create/' );
				}
			}
		)
		.catch(
			function( error ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( error.message, error.type, false, true );
				actionAutoremoveAlerts();
			}
		);
}
function actionSelectionTaskClientNameItem()
{
	document.querySelector( '.action-selection-client' ).style.display = 'none';
	document.querySelector( 'input[name="client_id"]' ).value = this.getAttribute( 'data-id' );
	document.querySelector( 'input[name="client_name"]' ).value = this.getAttribute( 'data-name' );

	populateTaskClientWorkstation( this.getAttribute( 'data-id' ) );
}
function actionSelectionTaskClientNameChange()
{
	document.querySelector( 'select[name="workstation_id"]' ).innerHTML = '';
	document.querySelector( 'select[name="workstation_id"]' ).style.display = 'block';
	document.querySelector( '.action-no-workstation' ).style.display = 'none';
}
function actionSelectionTaskClientName()
{
	var filters = {
		"filterName": "q",
		"filterItems": [
			{
				"filterSubName": "q",
				"filterOperator": "%LIKE%",
				"filterOperatorBy": "OR",
				"filterType": "text",
				"filterKey": [
					"company_name",
					"company_cif"
				],
				"filterValue": [
					this.value
				]
			}
		]
	};

	requestHTTP( 'POST', apiURLS.clientsSearch, JSON.stringify({ filters: filters }) )
		.then(
			function( resolve ) {
				if( resolve.type == 'success' && resolve.results.length > 0 )
				{
					var str = '<ul class="overlay-selection">';
					forEach( resolve.results, function ( index, elem ) {
						str += '<li data-id="' + elem.id + '" data-name="' + elem.company_cif + ' - ' + elem.company_name + '">';
							str += '<span>';
								str += elem.company_cif + ' - ' + elem.company_name;
							str += '</span>';
						str += '</li>';
					});
					str += '</ul>';

					document.querySelector( '.action-selection-client' ).innerHTML = str;
					document.querySelector( '.action-selection-client' ).style.display = 'block';

					addEventToNodeList( document.querySelectorAll( '.action-selection-client .overlay-selection li' ), 'click', actionSelectionTaskClientNameItem );
				}
			},
			function( reject ){}
		)
		.catch(
			function( error ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( error.message, error.type, false, true );
				actionAutoremoveAlerts();
			}
		);

	closeOverlay();
}
function populateTaskUsersByDepartment()
{
	if ( typeof taskUsersByDepartment !== 'undefined' && typeof listDepartments !== 'undefined' ) {
		var selectedValue = document.querySelector( 'select[name="department_id"]' ).value;
		var selectedOption = document.querySelector( 'select[name="admin_id"]' ).getAttribute( 'data-selected' );
		if( selectedValue.length > 0 && listDepartments[selectedValue] )
		{
			var options = '';
			forEach( taskUsersByDepartment[listDepartments[selectedValue]['name']], function ( index, elem ) {
				options += '<option value="' + elem['id'] + '"' + ( selectedOption == elem['id'] ? ' selected="selected"':'' ) + '>' + elem['name'] + '</option>';
			});

			document.querySelector( 'select[name="admin_id"]' ).innerHTML = options;
		}
	}
}
function actionSelectionTaskUsersByDepartment()
{
	if ( typeof taskUsersByDepartment !== 'undefined' && typeof listDepartments !== 'undefined' ) {
		var selectedValue = document.querySelector( 'select[name="department_id"]' ).value;

		if( selectedValue.length > 0 && listDepartments[selectedValue] )
		{
			var options = '';
			forEach( taskUsersByDepartment[listDepartments[selectedValue]['name']], function ( index, elem ) {
				options += '<option value="' + elem['id'] + '"' + '' + '>' + elem['name'] + '</option>';
			});

			document.querySelector( 'select[name="admin_id"]' ).innerHTML = options;
		}
	}
}
function actionSelectionContract()
{
	var allElem = document.querySelectorAll( 'div.contract-specific' );
	for ( var i = 0; i < allElem.length; i++ )
		addClass( allElem[i], 'nothing' );
	addClass( document.querySelector( '#form-item .form div.contract-common' ), 'nothing' );
	addClass( document.querySelector( '#form-item .form div.contract-documents' ), 'nothing' );

	var elemContractSpecific = document.querySelector('div.contract-specific-' + this.options[this.selectedIndex].getAttribute('data-group') );

	if( elemContractSpecific ) {
		var cloneContractSpecific = elemContractSpecific.cloneNode(true);
		document.querySelector( "#add-specific-fields" ).innerHTML = cloneContractSpecific.outerHTML;

		if( document.querySelector( '#form-item .form input[name="contract_numar"]' ) )
			document.querySelector( '#form-item .form input[name="contract_numar"]' ).value = this.options[this.selectedIndex].getAttribute('data-index');

		removeClass( document.querySelector( '#form-item .form div.contract-specific-' + this.options[this.selectedIndex].getAttribute('data-group') ), 'nothing' );
		removeClass( document.querySelector( '#form-item .form div.contract-common' ), 'nothing' );
		if( typeof contractsNamesKind !== 'undefined' ){
			if( this.options[this.selectedIndex].value && contractsNamesKind[this.options[this.selectedIndex].value] ) {
				addClass( document.querySelector( '#form-item .form div.contract-common' ), 'nothing' );
				removeClass( document.querySelector( '#form-item .form div.contract-documents' ), 'nothing' );
			}
		}

		var contractSelectedValue = this.value;
		var rowElem = document.querySelector( '#form-item .form' );

		removeClass( document.querySelector( '.form-item-assign-to' ), 'nothing' );
		// if( parseInt( contractSelectedValue ) !== 1 )
			// addClass( document.querySelector( '.form-item-assign-to' ), 'nothing' );

		actionPopulateTip( contractSelectedValue );
		if( PAGE === 'ClientsWorkstationsContractsCreate' )
			actionPopulateAssignedTo( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );
		actionPopulatePeriod( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );
		actionPopulatePrice( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value, rowElem.find( 'select[name="contract_perioada"]' )[0].value, );

		actionPopulatePayments( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );

		if( this.options[this.selectedIndex].getAttribute('data-group') == 'contract-de-service' )
			addEventToElement( document.querySelector( 'form .contract-specific-contract-de-service input[name="contract_serie"]' ), 'keyup', actionSetContractModel );

		actionSetContractModel();

		if( this.options[this.selectedIndex].getAttribute('data-group') == 'proces-verbal-parametrii-amef' ) {
			addEventToElement( document.querySelector( 'form .contract-specific-proces-verbal-parametrii-amef input[name="contract_serie_amef"]' ), 'keyup', function(){ actionSetModelAviz( document.querySelector( 'form .contract-specific-proces-verbal-parametrii-amef input[name="contract_serie_amef"]' ) ); } );
			actionSetModelAviz( document.querySelector( 'form .contract-specific-proces-verbal-parametrii-amef input[name="contract_serie_amef"]' ) );
		}

		if( this.options[this.selectedIndex].getAttribute('data-group') == 'mentenanta-comunicare-anaf' ) {
			var elContractSerieExists = document.querySelector( '#add-specific-fields .contract-specific-mentenanta-comunicare-anaf input[name="contract_serie"]' );
			if( elContractSerieExists ) {
				if( elContractSerieExists.value.startsWith( 'DB408' ) ) {
					document.querySelector( '#form-item select[name="contract_pret"]' ).value = '180.00';
				}
			}
		}
	}

	setTailDateSelectors();
}
function populateContract()
{
	if ( typeof contractsTipPeriodePrice !== 'undefined' && typeof contractsPaymentMethods !== 'undefined' ) {
		var contractSelectedValue = document.querySelector( 'select[name="contract"]' ).value;

		removeClass( document.querySelector( '.form-item-assign-to' ), 'nothing' );
		if( parseInt( contractSelectedValue ) !== 1 )
			addClass( document.querySelector( '.form-item-assign-to' ), 'nothing' );

		if( contractSelectedValue.length > 0 && contractsTipPeriodePrice[contractSelectedValue] )
		{
			document.querySelector( 'select[name="contract"]' ).dispatchEvent( new Event('change') );

			var rowElem = document.querySelector( '#form-item .form' );
			actionPopulateTip( contractSelectedValue );
			actionPopulateAssignedTo( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );
			actionPopulatePeriod( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );
			actionPopulatePayments( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );

			if( rowElem.find( 'select[name="contract_pret"]' ).length )
				actionPopulatePrice( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value, rowElem.find( 'select[name="contract_perioada"]' )[0].value, );
			else
				actionPopulatePrice( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value, rowElem.find( 'select[name="contract_perioada_other"]' )[0].value, );
		}
	}
}
function actionPopulateTip( contract )
{
	var options = '';
	// var rowElem = document.querySelector( 'div[data-name="' + contract + '"]' );
	var rowElem = document.querySelector( '#form-item .form' );
	var selectedOption = rowElem.find( 'select[name="contract_tip"]' )[0].getAttribute( 'data-selected' );
	if( contractsTipPeriodePrice[contract] )
		forEach( Object.keys( contractsTipPeriodePrice[contract] ), function ( index, elem ) {
			options += '<option value="' + elem + '"' + ( selectedOption == elem ? ' selected="selected"':'' ) + '>' + elem + '</option>';
		});
	if( rowElem.find( 'select[name="contract_tip"]' ).length ) {
		rowElem.find( 'select[name="contract_tip"]' )[0].innerHTML = options;
	}

	addEventToElement( document.querySelector( 'select[name="contract_tip"]' ), 'change', actionChangeTip );
}
function actionPopulateAssignedTo( contract, type ) {
	if ( typeof selectAssignedToForContract !== 'undefined' ) {
		if( selectAssignedToForContract[contract] )
			if( selectAssignedToForContract[contract][type] != null ) {
				if( typeof selectedAssignedTo !== 'undefined' && selectedAssignedTo !== '') {
					document.querySelector( 'select[name="assigned_to"]' ).value = selectedAssignedTo;
					selectedAssignedTo = '';
				}
				else
					document.querySelector( 'select[name="assigned_to"]' ).value = selectAssignedToForContract[contract][type];
			}
	}
}
function actionPopulatePeriod( contract, type )
{
	var options = '';
	// var rowElem = document.querySelector( 'div[data-name="' + contract + '"]' );
	var rowElem = document.querySelector( '#form-item .form' );
	var selectedOption = '';
	if( rowElem.find( 'select[name="contract_perioada"]' ).length ) selectedOption = rowElem.find( 'select[name="contract_perioada"]' )[0].getAttribute( 'data-selected' );
	if( rowElem.find( 'select[name="contract_perioada_other"]' ).length ) selectedOption = rowElem.find( 'select[name="contract_perioada_other"]' )[0].getAttribute( 'data-selected' );

	if( contractsTipPeriodePrice[contract][type] )
		forEach( Object.keys( contractsTipPeriodePrice[contract][type] ), function ( index, elem ) {
			options += '<option value="' + elem + '"' + ( selectedOption == elem ? ' selected="selected"':'' ) + '>' + elem + '</option>';
		});
	if( rowElem.find( 'select[name="contract_perioada"]' ).length ) rowElem.find( 'select[name="contract_perioada"]' )[0].innerHTML = options;
	if( rowElem.find( 'select[name="contract_perioada_other"]' ).length ) rowElem.find( 'select[name="contract_perioada_other"]' )[0].innerHTML = options;

	if( rowElem.find( 'select[name="contract_perioada"]' ).length )  addEventToElement( document.querySelector( 'select[name="contract_perioada"]' ), 'change', actionChangePeriode );
	if( rowElem.find( 'select[name="contract_perioada_other"]' ).length )  addEventToElement( document.querySelector( 'select[name="contract_perioada_other"]' ), 'change', actionChangePeriodeOther );
}
function actionPopulatePrice( contract, type, periode )
{
	var options = '';
	// var rowElem = document.querySelector( 'div[data-name="' + contract + '"]' );
	var rowElem = document.querySelector( '#form-item .form' );
	var selectedOption = '';
	if( rowElem.find( 'select[name="contract_pret"]' ).length ) selectedOption = rowElem.find( 'select[name="contract_pret"]' )[0].getAttribute( 'data-selected' );
	if( rowElem.find( 'select[name="contract_pret_other"]' ).length ) selectedOption = rowElem.find( 'select[name="contract_pret_other"]' )[0].getAttribute( 'data-selected' );

	if( contract && type && periode && contractsTipPeriodePrice[contract][type][periode] )
		forEach( contractsTipPeriodePrice[contract][type][periode], function ( index, elem ) {
			options += '<option value="' + elem + '"' + ( selectedOption == elem ? ' selected="selected"':'' ) + '>' + ( Number( elem ) ).toLocaleString('ro-RO', { style: "currency", currency: "Lei" } ) + '</option>';
		});
	if( rowElem.find( 'select[name="contract_pret"]' ).length ) rowElem.find( 'select[name="contract_pret"]' )[0].innerHTML = options;
	if( rowElem.find( 'select[name="contract_pret_other"]' ).length ) rowElem.find( 'select[name="contract_pret_other"]' )[0].innerHTML = options;

	if( !selectedOption.length ) {
		if( document.querySelector( '#form-item select[name="contract"] option:checked' ).getAttribute('data-group') == 'mentenanta-comunicare-anaf' ) {
			var elContractSerieExists = document.querySelector( '#add-specific-fields .contract-specific-mentenanta-comunicare-anaf input[name="contract_serie"]' );
			if( elContractSerieExists ) {
				if( elContractSerieExists.value.startsWith( 'DB408' ) ) {
					document.querySelector( '#form-item select[name="contract_pret"]' ).value = '180.00';
				}
			}
		}
	}
}
function actionPopulatePayments( contract, type )
{
	var options = '';
	var selectedOption = document.querySelector( 'select[name="contract_payment"]' ).getAttribute( 'data-selected' );

	// forEach( Object.keys( contractsPaymentMethods[contract][type] ), function ( index, elem ) {
	// 	options += '<option value="' + elem + '"' + ( selectedOption == elem ? ' selected="selected"':'' ) + '>' + GLOBALS['contracts_payments'][contract][type][elem] + '</option>';
	// });

	if( contractsPaymentMethods[type] )
		forEach( Object.keys( contractsPaymentMethods[type] ), function ( index, elem ) {
			options += '<option value="' + elem + '"' + ( selectedOption == elem ? ' selected="selected"':'' ) + '>' + GLOBALS['contracts_payments'][type][elem] + '</option>';
		});
	document.querySelector( 'select[name="contract_payment"]' ).innerHTML = options;
}
function actionChangeTip()
{
	var contractSelectedValue = document.querySelector( 'select[name="contract"]' ).value;
	// var rowElem = document.querySelector( 'div[data-name="' + contractSelectedValue + '"]' );
	var rowElem = document.querySelector( '#form-item .form' );

	if( rowElem.find( 'select[name="contract_tip"]' )[0].value ) {
		actionPopulatePeriod( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );
		actionPopulateAssignedTo( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );
	}

	if( rowElem.find( 'select[name="contract_tip"]' )[0].value && rowElem.find( 'select[name="contract_perioada"]' )[0].value )
		actionPopulatePrice( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value, rowElem.find( 'select[name="contract_perioada"]' )[0].value, );
	// else
	// 	actionPopulatePrice( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value, rowElem.find( 'select[name="contract_perioada_other"]' )[0].value, );

	if( rowElem.find( 'select[name="contract_tip"]' )[0].value )
		actionPopulatePayments( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value );
}
function actionChangePeriode()
{
	var contractSelectedValue = document.querySelector( 'select[name="contract"]' ).value;
	// var rowElem = document.querySelector( 'div[data-name="' + contractSelectedValue + '"]' );
	var rowElem = document.querySelector( '#form-item .form' );
	if( rowElem.find( 'select[name="contract_tip"]' )[0].value && rowElem.find( 'select[name="contract_perioada"]' )[0].value )
		actionPopulatePrice( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value, rowElem.find( 'select[name="contract_perioada"]' )[0].value, );
}
function actionChangePeriodeOther()
{
	var contractSelectedValue = document.querySelector( 'select[name="contract"]' ).value;
	var rowElem = document.querySelector( 'div[data-name="' + contractSelectedValue + '"]' );
	if( rowElem.find( 'select[name="contract_tip"]' )[0].value && rowElem.find( 'select[name="contract_perioada_other"]' )[0].value )
		actionPopulatePrice( contractSelectedValue, rowElem.find( 'select[name="contract_tip"]' )[0].value, rowElem.find( 'select[name="contract_perioada_other"]' )[0].value, );
}
function actionShowNoteForm()
{
	//var editor = null;
	var str = '<form class="overlay-form overlay-form-add-note form form-default" method="post" action=""><input type="hidden" name="formTaskNote" value="ok"><input type="hidden" name="task_id" value="' + this.getAttribute( 'task-id' ) + '">';
	var overlayEl = document.querySelector( '#overlay' );

	str += '<div class="form-row">'
			+ '<div class="form-item form-item-double">'
				+ '<textarea class="form-control form-control-lg editorRich" name="note"></textarea>'
			+ '</div>'
		+ '</div>';

	overlayEl.find( '.overlay-title' )[0].innerHTML = GLOBALS['listing']['add_note'];
	overlayEl.find( '.overlay-body' )[0].innerHTML = str;
	overlayEl.find( '.overlay-buttons-container' )[0].innerHTML = '<a class="btn btn-default btn-action"><i class="far fa-plus-square"></i> ' + GLOBALS['buttons']['add'] + '</a>';
	document.querySelector( '#overlay .overlay-buttons .btn-action' ).onclick = function(){
		//editor.save();
		document.querySelector( '#overlay form.form' ).submit();
	}
	overlayEl.style.display = 'block';

	/*editor = suneditor.create(( document.querySelector( '.editorRich' ) ),{
		stickyToolbar: false,
		plugins: [
			plugins.align, plugins.fontSize, plugins.fontColor, plugins.hiliteColor, plugins.horizontalRule, plugins.list, plugins.table, plugins.formatBlock, plugins.link
		],
		fontSize: [
			8, 10, 14, 18, 24, 36
		],
		formats: [
			'P', 'H[2-6]'
		],
		buttonList: [
			['undo', 'redo', 'fontSize', 'formatBlock'],
			['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
			['fontColor', 'hiliteColor', 'indent', 'outdent', 'align', 'horizontalRule', 'list', 'table'],
			['link', 'fullScreen', 'showBlocks']
		],
		width : '100%',
		lang: lang['ro'],
	});*/

	tinymce.init( tinyMCEOptions );
}
function actionAlertBefore( event )
{
	event.preventDefault();

	if( confirm( this.getAttribute( 'data-alert' ) ) )
		top.location.href = this.getAttribute( 'href' );

	return false;
}
function actionChangeStatusName()
{
	if( this.parentNode.find( '.action-change-status' ) )
		this.parentNode.find( '.action-change-status' )[0].click();
}
function actionChangeStatus()
{
	if( !listStatusesByName )
		return;

	var str = '<ul class="overlay-selection overlay-status-selection" task-id="' + this.getAttribute( 'task-id' ) + '">';
	var overlayEl = document.querySelector( '#overlay' );

	forEach( Object.keys( listStatusesByName ), function ( index, elem ) {
		str += '<li status-id="' + listStatusesByName[elem].id + '">';
			str += '<span>';
				str += listStatusesByName[elem].name;
			str += '</span>';
		str += '</li>';
	});

	str += '</ul>';

	overlayEl.find( '.overlay-title' )[0].innerHTML = GLOBALS['listing']['change_status'];
	overlayEl.find( '.overlay-body' )[0].innerHTML = str;
	overlayEl.style.display = 'block';

	addEventToNodeList( document.querySelectorAll( '.overlay-status-selection li' ), 'click', actionChangeTaskStatus );
}
function actionReportContractModel() {
	document.querySelector( '.filters form.form-default input[name="q"]' ).value = '';
	document.querySelector( '.filters form.form-default select[name="contract"]' ).value = this.getAttribute( 'data-id' );
	document.querySelector( '.filters form.form-default' ).submit();
}
function actionChangeTaskStatus()
{
	var task_id = this.parentNode.getAttribute( 'task-id' );
	if( parseInt( task_id ) <= 0 )
	{
		document.querySelector( '#alerts' ).innerHTML = templateDefault( GLOBALS['errors']['generic']['actionnotallowed'], 'error', false, true );
		actionAutoremoveAlerts();
	}

	requestHTTP( 'POST', apiURLS.setTaskStatus, JSON.stringify({ task_id: task_id, status_id: this.getAttribute( 'status-id' ) }))
		.then(
			function( resolve ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( resolve.message, resolve.type, false, true );
				if( resolve.type == 'success' && resolve.results.length )
					document.querySelector( 'span[task-id="' + task_id + '"]' ).innerHTML = resolve.results;
				actionAutoremoveAlerts();
				closeOverlay();
			},
			function( reject ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( reject.message, reject.type, false, true );
				actionAutoremoveAlerts();
				closeOverlay();
			}
		)
		.catch(
			function( error ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( error.message, error.type, false, true );
				actionAutoremoveAlerts();
				closeOverlay();
			}
		);
}
function actionAssignTaskName()
{
	if( this.parentNode.find( '.action-assign-task' ) )
		this.parentNode.find( '.action-assign-task' )[0].click();
}
function actionAssignTask()
{
	if( !listUsers[this.getAttribute( 'task-department' )] )
		return;

	var str = '<ul class="overlay-selection overlay-user-selection" task-id="' + this.getAttribute( 'task-id' ) + '">';
	var overlayEl = document.querySelector( '#overlay' );

	forEach( listUsers[this.getAttribute( 'task-department' )], function ( index, elem ) {
		str += '<li user-id="' + elem.id + '">';
			str += '<span>';
				str += elem.name;
			str += '</span>';
		str += '</li>';
	});

	str += '</ul>';

	overlayEl.find( '.overlay-title' )[0].innerHTML = GLOBALS['listing']['assign_users_from'] + ' ' + this.getAttribute( 'task-department' );;
	overlayEl.find( '.overlay-body' )[0].innerHTML = str;
	overlayEl.style.display = 'block';

	addEventToNodeList( document.querySelectorAll( '.overlay-user-selection li' ), 'click', actionAssignUserToTask );
}
function actionAssignUserToTask()
{
	var task_id = this.parentNode.getAttribute( 'task-id' );

	requestHTTP( 'POST', apiURLS.setTaskUser, JSON.stringify({ task_id: task_id, user_id: this.getAttribute( 'user-id' ) }) )
		.then(
			function( resolve ) {

				document.querySelector( '#alerts' ).innerHTML = templateDefault( resolve.message, resolve.type, false, true );
				if( resolve.type == 'success' && resolve.results.length )
					document.querySelector( 'span[task-id="' + task_id + '"]' ).innerHTML = resolve.results;

				actionAutoremoveAlerts();
				closeOverlay();
			},
			function( reject ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( reject.message, reject.type, false, true );
				actionAutoremoveAlerts();
				closeOverlay();
			}
		)
		.catch(
			function( error ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( error.message, error.type, false, true );
				actionAutoremoveAlerts();
				closeOverlay();
			}
		);
}
function actionAutoremoveAlerts( seconds = 3 )
{
	setTimeout( function(){
		forEach( document.querySelectorAll( '#alerts .section-alerts.autoremove' ), function ( index, elem ) { fadeOutAndRemove( elem, 2000 ); });
	}, seconds * 1000 );
}
function actionGetCompanyData()
{
	var cif = document.querySelector( 'input[name="company_cif"]' ).value;
	if( !cif || !validateCIF( cif ) )
	{
		document.querySelector( '#alerts' ).innerHTML = templateDefault( GLOBALS['errors']['generic']['cif'], 'error', true );
		addEventAlertClose();
		return;
	}

	requestHTTP( 'POST', apiURLS.ajaxGetCompany, JSON.stringify({ cif: cif }) )
		.then(
			function( resolve ) {
				if( resolve.type == "success" )
				{
					document.querySelector( 'input[name="scpTVA_mesaj"]' ).value = resolve.results.mesaj_ScpTVA;
					document.querySelector( 'select[name="scpTVA"]' ).value = resolve.results.scpTVA;
					document.querySelector( 'input[name="company_name"]' ).value = resolve.results.company_name;
					document.querySelector( 'textarea[name="company_adresa"]' ).value = resolve.results.company_adresa;
					document.querySelector( 'input[name="company_zip"]' ).value = resolve.results.company_zip;
					document.querySelector( 'input[name="company_city"]' ).value = resolve.results.company_city;
					document.querySelector( 'input[name="company_region"]' ).value = resolve.results.company_region;
					document.querySelector( 'input[name="company_country"]' ).value = resolve.results.company_country;

					document.querySelector( 'input[name="company_reprezentant"]' ).value = resolve.results.company_reprezentant;
					document.querySelector( 'input[name="company_iban"]' ).value = resolve.results.company_iban;
					document.querySelector( 'input[name="company_bank"]' ).value = resolve.results.company_bank;
					document.querySelector( 'input[name="company_email"]' ).value = resolve.results.company_email;
					document.querySelector( 'input[name="company_tel"]' ).value = resolve.results.company_tel;
					document.querySelector( 'input[name="company_reg_com"]' ).value = resolve.results.company_reg_com;
				}
				else
				{
					document.querySelector( '#alerts' ).innerHTML = templateDefault( resolve.message, resolve.type, false, true );
					addEventAlertClose();
				}
			},
			function( reject ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( reject.message, reject.type, false, true );
				addEventAlertClose();
			}
		)
		.catch(
			function( error ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( error.message, error.type, false, true );
				addEventAlertClose();
			}
		);
}
function actionOptions()
{
	var allElem = document.querySelectorAll( '.action-options' );
	for ( var i = 0; i < allElem.length; i++ )
	{
		if( allElem[i].querySelector( '.listing-options' ) != this.querySelector( '.listing-options' ) )
			allElem[i].querySelector( '.listing-options' ).style.display = 'none';
	}

	var thisEl = this.querySelector( '.listing-options' ).style.display;
	if( thisEl == 'block' )
	{
		this.querySelector( '.listing-options' ).style.display = 'none';
	}
	else
	{
		this.querySelector( '.listing-options' ).style.display = 'block';
	}
}
function addEventEscDetected()
{
	document.onkeydown = function( evt )
	{
		evt = evt || window.event;
		var isEscape = false;
		if ( "key" in evt ) {
			isEscape = ( evt.key === "Escape" || evt.key === "Esc" );
		} else {
			isEscape = ( evt.keyCode === 27 );
		}
		if ( isEscape ) {
			closeOverlay();
			closeActionOptions();
		}
	};
}
function addEventOutsideElem( eventType = 'click', elemSelector, eventHandler )
{
	document.addEventListener( eventType, function (event)
	{
		if ( !getClosest( event.target, elemSelector ) ) {
			eventHandler();
		}
	}, false);
}
function actionFormReset()
{
	this.closest( 'form' ).nextElementSibling.submit();
}
function actionSendAmef( event ) {
	event.preventDefault();

	var str = '<form class="overlay-form overlay-form-send-email-amef form form-default" method="post" action=""><input type="hidden" name="formSendEmailAMEF" value="ok"><input type="hidden" name="contract_id" value="' + this.getAttribute( 'contract-id' ) + '">';
	var overlayEl = document.querySelector( '#overlay' );

	var strOptions = '';
	if( listEmailsToAdd.length > 0 ) {
		strOptions += '<select class="form-control" name="email">'
		for( var i = 0; i< listEmailsToAdd.length; i++ )  { strOptions += '<option value="' + listEmailsToAdd[i] + '">' + listEmailsToAdd[i] + '</option>' }
		strOptions += '</select>';
	}

	str += ''
		+ '<div class="form-row">'
			+ '<div class="form-item">'
				+ strOptions
			+ '</div>'
		+ '</div>'
		+ '<div class="form-row">'
			+ '<div class="form-item">'
			+ 	'<input type="text" class="form-control overlay-form-send-email-amef-emailother" name="emailother" placeholder="' + GLOBALS['page']['contract_amef']['choose_email_message'] + '">'
			+	'<div class="alert alert-danger overlay-form-send-email-amef-emailother-error nothing">' + GLOBALS['errors']['generic']['email'] + '</div>'
			+ '</div>'
		+ '</div>';

	overlayEl.find( '.overlay-title' )[0].innerHTML = GLOBALS['page']['contract_amef']['choose_email'];
	overlayEl.find( '.overlay-body' )[0].innerHTML = str;
	overlayEl.find( '.overlay-buttons-container' )[0].innerHTML = '<a class="btn btn-default btn-action"><i class="far fa-plus-square"></i> ' + GLOBALS['buttons']['send'] + '</a>';
	document.querySelector( '#overlay .overlay-buttons .btn-action' ).onclick = function(){
		var ok = true;
		if( document.querySelector( '#overlay form.form .overlay-form-send-email-amef-emailother' ).value !== '' ) {
			if( !validateEmail( document.querySelector( '#overlay form.form .overlay-form-send-email-amef-emailother' ).value ) ) {
				addClass( document.querySelector( '#overlay form.form .overlay-form-send-email-amef-emailother' ), 'error' );
				removeClass( document.querySelector( '#overlay form.form .overlay-form-send-email-amef-emailother-error' ), 'nothing' );
				ok = false;
			} else {
				removeClass( document.querySelector( '#overlay form.form .overlay-form-send-email-amef-emailother' ), 'error' );
				addClass( document.querySelector( '#overlay form.form .overlay-form-send-email-amef-emailother-error' ), 'nothing' );
			}
		}

		if( ok )
			document.querySelector( '#overlay form.form' ).submit();
	}
	overlayEl.style.display = 'block';
}
function actionIsComert( event ) {
	document.querySelector( '#form-item #set-workstation-name' ).value = '';
	document.querySelector( '#form-item #set-workstation-jud' ).value = '';
	document.querySelector( '#form-item #set-workstation-loc' ).value = '';
	document.querySelector( '#form-item #set-workstation-str' ).value = '';
	document.querySelector( '#form-item #set-workstation-nr' ).value = '';

	var allElem = document.querySelectorAll( '#form-item .action-hide-comert' );
	for ( var i = 0; i < allElem.length; i++ )
		removeClass( allElem[i], 'disp-none' );

	if( this.checked === true ) {
		document.querySelector( '#form-item form' ).reset();
		this.checked = true;
		document.querySelector( '#form-item #set-workstation-name' ).value = 'Comert ambulant';
		document.querySelector( '#form-item #set-workstation-jud' ).value = '-';
		document.querySelector( '#form-item #set-workstation-loc' ).value = '-';
		document.querySelector( '#form-item #set-workstation-str' ).value = '-';
		document.querySelector( '#form-item #set-workstation-nr' ).value = '-';

		for ( var i = 0; i < allElem.length; i++ )
			addClass( allElem[i], 'disp-none' );
	}
}
function actionSetContractModel( e ) {
	let theEl = document.querySelector( '#form-item form input[name="contract_serie"]' );
	if( theEl && theEl.value )
		setContractModelValue( theEl.value );
}
function getModelStringToTest( val ) {
	return {
		'strToCheck2' : val.substring( 0, 2 ).toUpperCase(),
		'strToCheck3' : val.substring( 0, 3 ).toUpperCase(),
		'strToCheck4' : val.substring( 0, 4 ).toUpperCase(),
		'strToCheck5' : val.substring( 0, 5 ).toUpperCase(),
		'strToCheck6' : val.substring( 0, 6 ).toUpperCase(),
		'strToCheck7' : val.substring( 0, 7 ).toUpperCase(),
	}
}
function setContractModelValue( val ) {
	let stringsToCheck = getModelStringToTest( val );

	let theModel = document.querySelector( 'form .contract-specific-contract-de-service input[name="contract_model"]' );

	if( theModel ) {
		if( listModelSeries[stringsToCheck.strToCheck7] ) {
			theModel.value = listModelSeries[stringsToCheck.strToCheck7]['model'];
		} else if( listModelSeries[stringsToCheck.strToCheck6] ) {
			theModel.value = listModelSeries[stringsToCheck.strToCheck6]['model'];
		} else if( listModelSeries[stringsToCheck.strToCheck5] ) {
			theModel.value = listModelSeries[stringsToCheck.strToCheck5]['model'];
		} else if( listModelSeries[stringsToCheck.strToCheck4] ) {
			theModel.value = listModelSeries[stringsToCheck.strToCheck4]['model'];
		} else if( listModelSeries[stringsToCheck.strToCheck3] ) {
			theModel.value = listModelSeries[stringsToCheck.strToCheck3]['model'];
		} else if( listModelSeries[stringsToCheck.strToCheck2] ) {
			theModel.value = listModelSeries[stringsToCheck.strToCheck2]['model'];
		}
	}

	let theSerieDispozitiv = document.querySelector( 'form .contract-specific-declaratie-de-instalare input[name="contract_serie"]' );
	if( theSerieDispozitiv ) {

		let theAvizItem = (
			listModelSeries[stringsToCheck.strToCheck7]
				? listModelSeries[stringsToCheck.strToCheck7] :
				( listModelSeries[stringsToCheck.strToCheck6]
					? listModelSeries[stringsToCheck.strToCheck6] :
					( listModelSeries[stringsToCheck.strToCheck5]
						? listModelSeries[stringsToCheck.strToCheck5] :
						( listModelSeries[stringsToCheck.strToCheck4]
							? listModelSeries[stringsToCheck.strToCheck4] :
							( listModelSeries[stringsToCheck.strToCheck3]
								? listModelSeries[stringsToCheck.strToCheck3] :
								( listModelSeries[stringsToCheck.strToCheck2]
									? listModelSeries[stringsToCheck.strToCheck2] : null ) ) ) ) )
	);

		if( theAvizItem ) {
			document.querySelector( 'form .contract-specific-declaratie-de-instalare select[name="REPLACE_DI_TIP"]' ).value = theAvizItem.dataName;
			document.querySelector( 'form .contract-specific-declaratie-de-instalare select[name="REPLACE_DI_MODEL"]' ).value = theAvizItem.dataModel;
			document.querySelector( 'form .contract-specific-declaratie-de-instalare select[name="REPLACE_DI_AVIZ"]' ).value = theAvizItem.dataAviz;
		}
	}
}

function actionSetModelAviz( e ) {
	let strToCheck2 = e.value.substring( 0, 2 ).toUpperCase();
	let strToCheck3 = e.value.substring( 0, 3 ).toUpperCase();
	let strToCheck4 = e.value.substring( 0, 4 ).toUpperCase();
	let strToCheck5 = e.value.substring( 0, 5 ).toUpperCase();
	let strToCheck6 = e.value.substring( 0, 6 ).toUpperCase();
	let strToCheck7 = e.value.substring( 0, 7 ).toUpperCase();

	let theModel = document.querySelector( 'form .contract-specific-proces-verbal-parametrii-amef select[name="REPLACE_AMEF_PRODUCTS"]' );
	if( listModelSeries[strToCheck7] ) {
		theModel.value = listModelSeries[strToCheck7]['aviz'];
	} else if( listModelSeries[strToCheck6] ) {
		theModel.value = listModelSeries[strToCheck6]['aviz'];
	} else if( listModelSeries[strToCheck5] ) {
		theModel.value = listModelSeries[strToCheck5]['aviz'];
	} else if( listModelSeries[strToCheck4] ) {
		theModel.value = listModelSeries[strToCheck4]['aviz'];
	} else if( listModelSeries[strToCheck3] ) {
		theModel.value = listModelSeries[strToCheck3]['aviz'];
	} else if( listModelSeries[strToCheck2] ) {
		theModel.value = listModelSeries[strToCheck2]['aviz'];
	}
}

//TEMPLATES
function templateGenericError()
{
	return '<section class="section-alerts"><div class="alert alert-error" role="alert"><p>' + GLOBALS['errors']['generic']['error'] + '</p><div class="alert-close"><button class="btn btn-default">Close <i class="fas fa-times"></i></button></div></div></section>';
}
function templateDefault( message, type = 'error', hasclose = false, atuoremove = false )
{
	return '<section class="section-alerts'+ ( ( atuoremove == true ) ? ' autoremove':'' ) + '">'
			+ '<div class="alert alert-' + type + '" role="alert">'
				+ '<p>'
					+ message
				+ '</p>'
				+ ( ( hasclose == true ) ? '<div class="alert-close"><button class="btn btn-default">Close <i class="fas fa-times"></i></button></div>' : '' )
			+ '</div></section>';
}

function requestHTTP( method = 'GET', url, params = null, headers = [], timeOut = 10000, eventListeners = [], isUpload = false )
{
	return new Promise( function ( resolve, reject ) {
 
		var returnInfo = { "type": "", "message": "", "results" : [], "statusCode" : 0 };

		if( url == '' ) reject( returnInfo );

		let body = ( ( params != null && Array.isArray( params ) && params.length > 0 ) ? JSON.stringify( params ) : null ) 
			|| ( ( params != null && params.length > 0 && isJson( params ) ) ? params : null )
			|| ( ( params != null && params.length > 0 && params.startsWith( "data" ) ) ? params : null )
			|| params;
		
		var xhr = new XMLHttpRequest();

		xhr.open( method, url, true );
		xhr.timeout = timeOut;
		
		var listHeaders = {};
		listHeaders["X-Requested-With"] = "XMLHttpRequest";
		listHeaders["Content-Type"] = "application/x-www-form-urlencoded";

		if( headers.length )
			forEach( headers, function ( index, elem ) { listHeaders[elem['key']] = elem['value']; });
		forEach( headers, function ( index, elem ) { xhr.setRequestHeader( elem['key'], elem['value'] ); });
		
		if( eventListeners.length )
			if( isUpload )
				forEach( eventListeners, function ( index, elem ) { xhr.upload.addEventListener( elem['key'], elem['value'], false ); });
			else
				forEach( eventListeners, function ( index, elem ) { xhr.addEventListener( elem['key'], elem['value'] ); });

		// UNSENT 			= 0; // initial state
		// OPENED 			= 1; // open called
		// HEADERS_RECEIVED = 2; // response headers received
		// LOADING 			= 3; // response is loading (a data packed is received)
		// DONE 			= 4; // request complete

		if( !isUpload )
			xhr.onload = function()
			{
				returnInfo.statusCode = xhr.status;

				if( validateStringIsJSON( xhr.responseText ) )
				{
					var responseJson = JSON.parse( xhr.responseText );
					if( responseJson.response )
					{
						returnInfo.message 	= responseJson.response.message;
						returnInfo.type 	= responseJson.response.type;

						if( responseJson.response.results ) 	returnInfo.results 	= responseJson.response.results;
						if( responseJson.response.original ) 	returnInfo.original 	= responseJson.response.original;
						if( responseJson.response.statusName ) 	returnInfo.results 	= responseJson.response.statusName;
						if( responseJson.response.userName ) 	returnInfo.results 	= responseJson.response.userName;
						if( responseJson.response.info ) 		returnInfo.results 	= responseJson.response.info;
					}
				}

				returnInfo.statusCode === 200 ? resolve( returnInfo ) : reject( returnInfo );
			};

		if( isUpload )
			xhr.onloadend = function ()
			{
				returnInfo.statusCode = xhr.status;

				if( validateStringIsJSON( xhr.responseText ) )
				{
					var responseJson = JSON.parse( xhr.responseText );
					if( responseJson.response )
					{
						returnInfo.message 	= responseJson.response.message;
						returnInfo.type 	= responseJson.response.type;

						if( responseJson.response.results ) 	returnInfo.results 	= responseJson.response.results;
						if( responseJson.response.original ) 	returnInfo.original 	= responseJson.response.original;
						if( responseJson.response.statusName ) 	returnInfo.results 	= responseJson.response.statusName;
						if( responseJson.response.userName ) 	returnInfo.results 	= responseJson.response.userName;
						if( responseJson.response.info ) 		returnInfo.results 	= responseJson.response.info;
					}
				}

				returnInfo.statusCode === 200 ? resolve( returnInfo ) : reject( returnInfo );
			}

		xhr.onerror = function()
		{
			returnInfo.type 	= "error";
			returnInfo.message 	= GLOBALS['errors']['generic']['error'];

			reject( returnInfo );
		};

		xhr.send( body );
	});
}

//OTHER
function cleanTextForCSV( text )
{
	text = text.replace(/"/g, '\\"').replace(/'/g, "\\'").trim();
	return '"'+text+'"';
}
function listingToCSV( elem )
{
	if( !elem.find( '.listing' ).length )
		return;

	let listing = elem.find( '.listing' )[0];
	let headItm =  listing.find( '.listing-head' );
	let rowsItm =  listing.find( '.listing-row' );
	
	if( headItm.length && rowsItm.length )
	{
		var head = [];
		var rows = [];

		forEach( headItm[0].find( '.listing-item' ), function ( index, elem ) {
			var elText = elem.find( '.listing-value' )[0].textContent || elem.find( '.listing-value' )[0].innerText || "";
			head.push( cleanTextForCSV( elText ) );
		});
		forEach( rowsItm, function ( index, elem ) {
			var data = [];

			forEach( elem.find( '.listing-item' ), function ( index, el ) {
				var elText = el.find( '.listing-value' )[0].textContent || el.find( '.listing-value' )[0].innerText || "";
				data.push( cleanTextForCSV( elText ) );
			});
			rows.push( data.join( "," ) );
		});

		var csv = head.join( "," ) + "\n" + rows.join( "\n" );
		var uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent( csv );
		var csvDL = document.createElement( 'a' );
		csvDL.href = uri;
		csvDL.download = "export-error-" + ( new Date().getTime() ) + ".csv";
		document.body.appendChild( csvDL );
		csvDL.click();
		document.body.removeChild(csvDL);
	}
}
function setTailDateSelectors()
{
	if( allTail && allTail.length > 0 )
		for ( var i = 0; i < allTail.length; i++ )
			allTail[i].remove();

	// allTail = document.querySelectorAll( ':not(#add-specific-fields) > .taildatetime-container' );
	allTail = document.querySelectorAll( 'form #add-specific-fields .taildatetime-container' );
	if( allTail && allTail.length > 0 ) {
		for ( var i = 0; i < allTail.length; i++ )
		{
			var options = {};
			if( hasClass( allTail[i], 'tail-date' ) )
			{
				if( [ 'REPLACE_DI_DATA', 'REPLACE_DI_CI_DATA' ].includes( allTail[i].getAttribute('name') ) )
					options.dateFormat = 'dd.mm.YYYY';
				options.timeFormat = false;
				tail( allTail[i], options );
			}
			if( hasClass( allTail[i], 'tail-datetime' ) )
			{
				if( allTail[i].getAttribute( 'tail-position' ).length )
					options.position = allTail[i].getAttribute( 'tail-position' );
				tail( allTail[i], options );
			}
		}
	}
}
function setTailDateSelectorsCommon() {
	let allTailCustom = document.querySelectorAll( 'form .contract-common .taildatetime-container' );
	if( allTailCustom && allTailCustom.length > 0 ) {
		for ( var i = 0; i < allTailCustom.length; i++ )
		{
			var options = {};
			if( hasClass( allTailCustom[i], 'tail-date' ) )
			{
				options.timeFormat = false;
				tail( allTailCustom[i], options );
			}
			if( hasClass( allTailCustom[i], 'tail-datetime' ) )
			{
				if( allTailCustom[i].getAttribute( 'tail-position' ).length )
					options.position = allTailCustom[i].getAttribute( 'tail-position' );
				tail( allTailCustom[i], options );
			}
		}
	}
}
function addSortableNested( elemSelectors, eventHandler )
{
	var elemList = document.querySelectorAll( elemSelectors );
	if( !elemList.length )
		return;

	for ( var i = 0; i < elemList.length; i++ )
	{
		new sortable ( elemList[i],
		{
			group: 'shared',
			filter: '.section-sortable-header',
			draggable: '.section-sortable-item',
			onAdd: eventHandler,
			animation: 150,
		});
	}
}
function addSortableGroups( elemSelectors, eventHandler )
{
	var elemList = document.querySelectorAll( elemSelectors );
	if( !elemList.length )
		return;

	for ( var i = 0; i < elemList.length; i++ )
	{
		new sortable ( elemList[i],
		{
			group: 'shared',
			filter: '.section-sortable-header',
			draggable: '.section-sortable-item',
			onAdd: eventHandler,
			sort: false,
			animation: 150,
		});
	}
}
function sortDepartmentUsers()
{
	var departmentid = document.querySelector( '.section-sortable-right' ).getAttribute( 'department-id' );
	var itemsList = document.querySelectorAll( '.section-sortable-right .section-sortable-item' );
	var listOrder = [];
	for ( var i = 0; i < itemsList.length; i++ )
		listOrder.push( itemsList[i].getAttribute( 'item-id' ) );

	requestHTTP( 'POST', ( listOrder.length <= 0 ? apiURLS.departmentRemoveAllUsers : apiURLS.departmentAddUsers ), JSON.stringify({ users: listOrder, department_id: departmentid }) )
		.then(
			function( resolve ) {

				document.querySelector( '#alerts' ).innerHTML = templateDefault( resolve.message, resolve.type, false, true );
				actionAutoremoveAlerts();
			},
			function( reject ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( reject.message, reject.type, false, true );
				actionAutoremoveAlerts();
			}
		)
		.catch(
			function( error ) {
				document.querySelector( '#alerts' ).innerHTML = templateDefault( error.message, error.type, false, true );
				actionAutoremoveAlerts();
			}
		);
}
function addSortable( elemSelectors, eventHandler )
{
	var elemList = document.querySelectorAll( elemSelectors );

	if( !elemList.length )
		return;

	for ( var i = 0; i < elemList.length; i++ )
	{
		sortable.create( elemList[i],
		{
			group: 'single',
			filter: '.listing-sortable-not',
			draggable: '.listing-sortable',
			onUpdate: eventHandler,
		});
	}
}
function sortStatuses( event )
{
	if( event.newIndex != event.oldIndex )
	{
		var itemsList = event.to.querySelectorAll( '.listing-row.listing-sortable' );
		var listOrder = [];
		for ( var i = 0; i < itemsList.length; i++ )
			listOrder.push( itemsList[i].getAttribute( 'item-id' ) );

		requestHTTP( 'POST', apiURLS.sortStatuses, JSON.stringify({ items: listOrder }) )
			.then(
				function( resolve ) {
					document.querySelector( '#alerts' ).innerHTML = templateDefault( resolve.message, resolve.type, false, true );
					actionAutoremoveAlerts();
				},
				function( reject ) {
					document.querySelector( '#alerts' ).innerHTML = templateDefault( reject.message, reject.type, false, true );
					actionAutoremoveAlerts();
				}
			)
			.catch(
				function( error ) {
					document.querySelector( '#alerts' ).innerHTML = templateDefault( error.message, error.type, false, true );
					actionAutoremoveAlerts();
				}
			);
	}
}
function sortDepartments( event )
{
	if( event.newIndex != event.oldIndex )
	{
		var itemsList = event.to.querySelectorAll( '.listing-row.listing-sortable' );
		var listOrder = [];
		for ( var i = 0; i < itemsList.length; i++ )
			listOrder.push( itemsList[i].getAttribute( 'item-id' ) );

		requestHTTP( 'POST', apiURLS.sortDepartments, JSON.stringify({ items: listOrder }) )
			.then(
				function( resolve ) {
					document.querySelector( '#alerts' ).innerHTML = templateDefault( resolve.message, resolve.type, false, true );
					actionAutoremoveAlerts();
				},
				function( reject ) {
					document.querySelector( '#alerts' ).innerHTML = templateDefault( reject.message, reject.type, false, true );
					actionAutoremoveAlerts();
				}
			)
			.catch(
				function( error ) {
					document.querySelector( '#alerts' ).innerHTML = templateDefault( error.message, error.type, false, true );
					actionAutoremoveAlerts();
				}
			);
	}
}
function closeActionOptions()
{
	var allElem = document.querySelectorAll( '.listing-options' );
	for ( var i = 0; i < allElem.length; i++ )
		allElem[i].style.display = 'none';
}
function closeOverlay()
{
	var el = document.querySelector( '#overlay' );
	el.find( '.overlay-title' )[0].innerHTML = '';
	el.find( '.overlay-body' )[0].innerHTML = '';
	el.find( '.overlay-buttons-container' )[0].innerHTML = '';
	el.style.display = 'none';
}

//IS KIND
function isNumberInteger( number )
{
	if( parseInt( number ) === NaN || parseInt( number ) <= 0 ) return false;
	return true;
}
function isJson( item ) {
	item = typeof item !== "string" ? JSON.stringify(item) : item;

	try {
		item = JSON.parse(item);
	} catch (e) {
		return false;
	}

	if ( typeof item === "object" && item !== null ) {
		return true;
	}

	return false;
}
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
function isInt( number )
{
	if( parseInt( number ) === NaN ) return false;
	return true;
}
function isTime( time ) //format H:i:s
{
	if( time.length !== 8 )
		return false;
	if( time.split( ':' ).length != 3 )
		return false;

	return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test( time );
}
function isDate( date ) //format YYYY-mm-dd
{
	return /^\d{4}-\d{2}-\d{2}$/.test( date );
}
function getDateAndTimeFromString( str )
{
	let dateTime = str.split( " " )
	if( dateTime.length !== 2 )
		return false;

	if( !isTime( dateTime[1] ) )
		return false;

	let datePieces = dateTime[0].split( '-' );
	let timePieces = dateTime[1].split( ':' );

	var dateObj = new Date( parseInt( datePieces[0] ), parseInt( datePieces[1] ) - 1, parseInt( datePieces[2] ) );

	if( validateDateObject( dateObj ) )
	{
		dateObj.setSeconds( convertTimeStrToSeconds( dateTime[1] ) );

		return dateObj;
	}

	return false;
}
function convertTimeStrToSeconds( time )
{
	if( !time )
		return 0;

	let pieces = time.split( ':' );
	if( !pieces[0] || !pieces[1] || !pieces[2] || !isInt( pieces[0] ) || !isInt( pieces[1] ) || !isInt( pieces[2] ) )
		return 0;
	return parseInt( pieces[0] ) * 60 * 60 + parseInt( pieces[1] ) * 60 + parseInt( pieces[2] );
}
function convertStringToDateFromDateTime( str ) //format is YYYY-mm-dd H:i:s
{
	let dateTime = str.split( " " )
	if( dateTime.length !== 2 )
		return false;

	if( !isDate( dateTime[0] ) )
		return false;

	if( !isTime( dateTime[1] ) )
		return false;

	let datePieces = dateTime[0].split( '-' );
	let timePieces = dateTime[1].split( ':' );

	var dateObj = new Date( parseInt( datePieces[0] ), parseInt( datePieces[1] ) - 1, parseInt( datePieces[2] ) );

	if( validateDateObject( dateObj ) )
	{
		dateObj.setHours( parseInt( timePieces[0] ) );
		dateObj.setMinutes( parseInt( timePieces[1] ) );
		dateObj.setSeconds( parseInt( timePieces[2] ) );

		return dateObj;
	}

	return false;
}
function convertStringToDateFromDate( date ) //format is YYYY-mm-dd
{
	if( !isDate( date ) )
		return false;

	let datePieces = date.split( '-' );

	var dateObj = new Date( parseInt( datePieces[0] ), parseInt( datePieces[1] ) - 1, parseInt( datePieces[2] ) );

	if( validateDateObject( dateObj ) )
		return dateObj;

	return false;
}
function convertStringToDateFromTime( str )
{
	if( !isTime( str ) )
		return false;

	var dateObj = new Date();
	let timePieces = str.split( ':' );

	if( validateDateObject( dateObj ) )
	{
		dateObj.setHours( parseInt( timePieces[0] ) );
		dateObj.setMinutes( parseInt( timePieces[1] ) );
		dateObj.setSeconds( parseInt( timePieces[2] ) );

		return dateObj;
	}

	return false;
}

//VALIDATION
function validateURL( url )
{
	var pattern = new RegExp('^(https?:\/\/)?'+ // protocol
		'((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|'+ // domain name
		'((\d{1,3}\.){3}\d{1,3}))'+ // OR ip (v4) address
		'(\:\d+)?(\/[-a-z\d%_.~+]*)*'+ // port and path
		'(\?[;&a-z\d%_.~+=-]*)?'+ // query string
		'(\#[-a-z\d_]*)?$','i'); // fragment locater
	return pattern.test( url );
}
function validateDateObject( dateObj )
{
	if( Object.prototype.toString.call( dateObj ) === "[object Date]" )
		return true;
	return false;   
}
function validateStringIsJSON( string )
{
	try
	{
		JSON.parse( string );
		return true;
	}
	catch ( e )
	{
		return false;
	}
}
function validateCIF( cif )
{
	if( !isNumeric( cif ) ) return false;

	// Exista CUI -uri mai scurte de 6 cifre care sunt valide. (Ex. 5229, 52298)
	if ( cif.length < 4 || cif.length > 10 ) return false;

	return true;

	/*if( !isNumeric( cif ) ) return false;

	// Exista CUI -uri mai scurte de 6 cifre care sunt valide. (Ex. 5229, 52298)
	if ( cif.length < 4 || cif.length > 10 ) return false;

	var cifra_control = cif.substring( cif.length - 1, cif.length );
	cif = cif.substring( 0, cif.length - 1 );
	
	while ( cif.length != 9 ) cif = '0' + cif;

	var _suma = cif[0] * 7 + cif[1] * 5 + cif[2] * 3 + cif[3] * 2 + cif[4] * 1 + cif[5] * 7 + cif[6] * 5 + cif[7] * 3 + cif[8] * 2;
	var suma = _suma * 10;
	var rest = Math.fmod( suma, 11 );

	if ( rest == 10 ) rest = 0;
	if ( rest == cifra_control ) return true;

	return false;*/
}
function validateEmail( email ) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}
//DOM
function hasClass( target, className )
{
	return new RegExp( '(\\s|^)' + className + '(\\s|$)' ).test( target.className );
}
function addClass( target, className )
{
	if( target != null ) {
		let cn = target.className;
		if( cn.split(' ').indexOf( className ) != -1 ) return;
		//if( cn.indexOf( className ) != -1 ) return;
		if( cn != '' ) className = ' ' + className;
		target.className = cn + className;
	}
}
function removeClass( t,c )
{
	if( t != null ) {
		t.className = t.className.replace( new RegExp( '(?:^|\\s)' + c + '(?!\\S)' ),'' );
	}
}
function getClosest( elem, selector )
{
	for (; elem && elem !== document; elem = elem.parentNode)
	{
		if (elem.matches(selector)) return elem;
	}
	return null;
}

function addEventAlertClose()
{
	addEventToElement( document.querySelector( '.alert-close' ), 'click', function(){ document.querySelector( '#alerts' ).innerHTML = ''; });
}
function addEventToElement( elem, eventType = 'click', eventHandler, args = null )
{
	if( !elem )
		return;

	elem.addEventListener( eventType, eventHandler, args );
}
function addEventToNodeList( elem, eventType = 'click', eventHandler, args = null )
{
	if( !elem.length )
		return;

	for ( var i = 0; i < elem.length; i++ )
		elem[i].addEventListener( eventType, eventHandler, args);
}
function htmlEntities(str) {
	return String(str).replace(/&/g, '&amp;').replace(/</g,     '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

//supporting functions
function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
async function sleepExample() {
	await sleep(2000);
}

if ( !String.prototype.startsWith ) {
	Object.defineProperty( String.prototype, 'startsWith', {
		value: function(search, pos) {
			pos = !pos || pos < 0 ? 0 : +pos;
			return this.substring(pos, pos + search.length) === search;
		}
	});
}
if (typeof Array.isArray === 'undefined')
{
	Array.isArray = function(obj)
	{
		return Object.prototype.toString.call(obj) === '[object Array]';
	}
}
if ( !Element.prototype.closest )
{
	if ( !Element.prototype.matches )
	{
		Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
	}
	Element.prototype.closest = function (s)
	{
		var el = this;
		var ancestor = this;
		if ( !document.documentElement.contains( el ) ) return null;
		do {
			if ( ancestor.matches( s ) ) return ancestor;
			ancestor = ancestor.parentElement;
		} while ( ancestor !== null );
		return null;
	};
}
if ( !Node.prototype.find )
{
	Node.prototype.find = function(selector) {
		if (/(^\s*|,\s*)>/.test(selector)) {
			if (!this.id) {
				this.id = 'ID_' + ( new Date() ).getTime();
				var removeId = true;
			}
			selector = selector.replace(/(^\s*|,\s*)>/g, '$1#' + this.id + ' >');
			var result = document.querySelectorAll(selector);
			if (removeId) {
				this.removeAttribute( 'id' );
			}
			return result;
		} else {
			return this.querySelectorAll(selector);
		}
	};
}
if( !("nextElementSibling" in document.documentElement ) )
{
	Object.defineProperty(Element.prototype, "nextElementSibling", {
		get: function(){
			var e = this.nextSibling;
			while(e && 1 !== e.nodeType)
				e = e.nextSibling;
			return e;
		}
	});
}
var forEach = function( array, callback, scope ) {
	for ( var i = 0; i < array.length; i++ ) {
		callback.call(scope, i, array[i]); // passes back stuff we need
	}
};
var fadeOutAndRemove = function( el, speed ) {
	var seconds = speed/1000;
	el.style.transition = "opacity "+seconds+"s ease";

	el.style.opacity = 0;
	setTimeout(function() {
		el.parentNode.removeChild( el );
	}, speed);
}
Math.fmod = function (a,b) { return Number((a - (Math.floor(a / b) * b)).toPrecision(8)); };

(function (arr) {
  arr.forEach(function (item) {
	if (item.hasOwnProperty('nextElementSibling')) {
	  return;
	}
	Object.defineProperty(item, 'nextElementSibling', {
	  configurable: true,
	  enumerable: true,
	  get: function () {
		var el = this;
		while (el = el.nextSibling) {
		  if (el.nodeType === 1) {
			  return el;
		  }
		}
		return null;
	  },
	  set: undefined
	});
  });
})([Element.prototype, CharacterData.prototype]);